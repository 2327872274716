<template>
    <div>
        <PageHeader :title="'Posição Energética'" :items="breadcrumb" />

        <b-card class="mb-3 mt-3 card-pequeno" title="Ações">
            <b-container fluid class="ml-1 mt-1">
                <b-row align-v="center" class="my-1">
                    <b-col md="2">
                        <b-form-group label="Mês de Referência" label-for="filtroMesRef" class="form">
                            <b-form-select id="filtroMesRef" v-model="filtroMesRef" :options="meses"
                                @change="atualizarDataRef" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Ano de Referência" label-for="filtroAnoRef" class="form">
                            <b-form-input id="filtroAnoRef" type="number" v-model="filtroAnoRef"
                                @change="atualizarDataRef" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-button @click="getPosicao" class="buttom">Visulizar Posição</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-card>

        <b-spinner v-if="loading" text-align="center" variant="success" label="Spinning" class="ml-2 mt-3" />

        <b-card class="mb-3 mt-3 card-pequeno" v-if="posicao" title="Posição Sem Considerar Net Energético">
            <b-card-group deck>

                <b-card header="Venda CO" header-bg-variant="dark" header-text-variant="white" align="center"
                    class="card-pequeno">
                    <b-card-text>{{ formatNumber(this.posicao.posicaoSemNet.vendaCO, 3) }}</b-card-text>
                </b-card>

                <b-card header="Venda I0" header-bg-variant="dark" header-text-variant="white" align="center"
                    class="card-pequeno">
                    <b-card-text>{{ formatNumber(this.posicao.posicaoSemNet.vendaI0, 3) }}</b-card-text>
                </b-card>

                <b-card header="Venda I5 I1" header-bg-variant="dark" header-text-variant="white" align="center"
                    class="card-pequeno">
                    <b-card-text>{{ formatNumber(this.posicao.posicaoSemNet.vendaI5, 3) }}</b-card-text>
                </b-card>

                <b-card header="Venda INE CQ5" header-bg-variant="dark" header-text-variant="white" align="center"
                    class="card-pequeno">
                    <b-card-text>{{ formatNumber(this.posicao.posicaoSemNet.vendaIne, 3) }}</b-card-text>
                </b-card>

                <b-card header="Compra CO" header-bg-variant="dark" header-text-variant="white" align="center"
                    class="card-pequeno">
                    <b-card-text>{{ formatNumber(this.posicao.posicaoSemNet.compraCO, 3) }}</b-card-text>
                </b-card>

                <b-card header="Compra I0" header-bg-variant="dark" header-text-variant="white" align="center"
                    class="card-pequeno">
                    <b-card-text>{{ formatNumber(this.posicao.posicaoSemNet.compraI0, 3) }}</b-card-text>
                </b-card>

                <b-card header="Compra I5 I1" header-bg-variant="dark" header-text-variant="white" align="center"
                    class="card-pequeno">
                    <b-card-text>{{ formatNumber(this.posicao.posicaoSemNet.compraI5, 3) }}</b-card-text>
                </b-card>

                <b-card header="Compra INE CQ5" header-bg-variant="dark" header-text-variant="white" align="center"
                    class="card-pequeno">
                    <b-card-text>{{ formatNumber(this.posicao.posicaoSemNet.compraIne, 3) }}</b-card-text>
                </b-card>
            </b-card-group>
        </b-card>

        <b-card class="mb-3 mt-3 card-pequeno" v-if="posicao" title="Posição Considerando Net Energético">
            <b-card-group deck>

                <b-card header="Venda CO" header-bg-variant="dark" header-text-variant="white" align="center"
                    class="card-pequeno">
                    <b-card-text>{{ formatNumber(this.posicao.posicaoComNet.vendaCO, 3) }}</b-card-text>
                </b-card>

                <b-card header="Venda I0" header-bg-variant="dark" header-text-variant="white" align="center"
                    class="card-pequeno">
                    <b-card-text>{{ formatNumber(this.posicao.posicaoComNet.vendaI0, 3) }}</b-card-text>
                </b-card>

                <b-card header="Venda I5 I1" header-bg-variant="dark" header-text-variant="white" align="center"
                    class="card-pequeno">
                    <b-card-text>{{ formatNumber(this.posicao.posicaoComNet.vendaI5, 3) }}</b-card-text>
                </b-card>

                <b-card header="Venda INE CQ5" header-bg-variant="dark" header-text-variant="white" align="center"
                    class="card-pequeno">
                    <b-card-text>{{ formatNumber(this.posicao.posicaoComNet.vendaIne, 3) }}</b-card-text>
                </b-card>

                <b-card header="Compra CO" header-bg-variant="dark" header-text-variant="white" align="center"
                    class="card-pequeno">
                    <b-card-text>{{ formatNumber(this.posicao.posicaoComNet.compraCO, 3) }}</b-card-text>
                </b-card>

                <b-card header="Compra I0" header-bg-variant="dark" header-text-variant="white" align="center"
                    class="card-pequeno">
                    <b-card-text>{{ formatNumber(this.posicao.posicaoComNet.compraI0, 3) }}</b-card-text>
                </b-card>

                <b-card header="Compra I5 I1" header-bg-variant="dark" header-text-variant="white" align="center"
                    class="card-pequeno">
                    <b-card-text>{{ formatNumber(this.posicao.posicaoComNet.compraI5, 3) }}</b-card-text>
                </b-card>

                <b-card header="Compra INE CQ5" header-bg-variant="dark" header-text-variant="white" align="center"
                    class="card-pequeno">
                    <b-card-text>{{ formatNumber(this.posicao.posicaoComNet.compraIne, 3) }}</b-card-text>
                </b-card>
            </b-card-group>
        </b-card>
    </div>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';
import meses from '@/utils/meses';
import PageHeader from '@/components/page-header';
import { getPosicao } from './PosicaoEnergetica.service';

Vue.use(VueMask);

export default {
    data() {
        return {
            breadcrumb: [
                {
                    text: 'Backoffice',
                    href: '#'
                },
                {
                    text: 'Posição Energética',
                    active: true
                }
            ],
            meses: meses(),
            mesRef: '',
            filtroMesRef: new Date().getMonth() + 1,
            filtroAnoRef: new Date().getFullYear(),
            mesRefInput: '',
            posicao: null,
            loading: null
        }
    },
    components: {
        PageHeader
    },
    created() {
        this.atualizarDataRef();
    },
    methods: {
        formatNumber(number, decimals) {
            // Verifica se o número é zero
            if (number == 0) {
                return 0;
            } else {
                // Caso contrário, formata o número com o número especificado de casas decimais
                return number.toLocaleString('pt-BR', { minimumFractionDigits: decimals });
            }
        },
        atualizarDataRef() {
            if (this.filtroMesRef && this.filtroAnoRef) {
                const mesIndexZeroBase = this.filtroMesRef - 1;
                this.mesRefInput = `${this.filtroAnoRef}-${String(
                    mesIndexZeroBase + 1
                ).padStart(2, '0')}-01`;
            }
        },
        getPosicao() {
            this.loading = true;

            getPosicao(this.mesRefInput)
                .then((response) => {
                    if (response.status) {
                        this.posicao = response.content;
                    } else {
                        console.error(
                            'Resposta do servidor não está no formato esperado:',
                            response
                        );
                    }
                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    }
}
</script>

<style>
.card-pequeno {
    font-size: 12px;
}

.card-pequeno .card-header {
    padding: 3px;
}

.card-pequeno .card-body {
    padding: 3px;
}
</style>